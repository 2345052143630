<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
        </ion-buttons>
        <ion-title>New Crawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="--ion-color-primary-contrast">
              <h1 style="margin-bottom: 0; text-align: center"><strong>Where to?</strong></h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-card @click="setNewLocation(1)">
        <ion-card-header>
          <ion-card-subtitle>Des Moines, IA</ion-card-subtitle>
          <ion-card-title>East Village</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <img src="/assets/images/eastvil.jpg" />
        </ion-card-content>
      </ion-card>
      <!--ion-card @click="setNewLocation(3)">
        <ion-card-header>
          <ion-card-subtitle>Des Moines, IA</ion-card-subtitle>
          <ion-card-title>Court Avenue</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <img src="/assets/images/ctave.jpg" />
        </ion-card-content>
      </ion-card>
      <ion-card @click="setNewLocation(4)">
        <ion-card-header>
          <ion-card-subtitle>West Des Moines, IA</ion-card-subtitle>
          <ion-card-title>West Glen</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <img src="/assets/images/westglen.jpg" />
        </ion-card-content>
      </ion-card-->
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  alertController,
  //IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonSlides,
  //IonSlide,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/vue';
import { pin, walk, warning, wifi, wine } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Plugins } from '@capacitor/core';
import * as apiMethods from '../app/api.js'

export default defineComponent({
  name: 'NewCrawlLocation',
  components: {
    IonBackButton,
    IonButtons,
    //IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonSlides,
    // IonSlide,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  setup() {
    const { Storage, Device } = Plugins;
    apiMethods.crawlApi.Storage = Storage;
    apiMethods.crawlApi.Device = Device;
    return {  warning,
              console,
              router: useRouter(),
              Storage,
              Device }
  },
  mounted: async function() {
    console.log('Mounted.');
    apiMethods.crawlApi.init();
  },
  ionViewWillEnter: async function() {
    const self = this;
    console.log('ionviewwillenter');
    const nameFetched = await self.getName();
    //if(!nameFetched || 'crawler' == nameFetched) {
      console.log(nameFetched);
      await this.presentAlertPrompt();
    //}
  },
  methods: {
    setNewLocation: async function(locationId) {
      await this.Storage.set({
        key: 'newCrawlLocation',
        value: JSON.stringify({
          id: locationId
        })
      });

      this.router.push('/new-num-stops');
    },
    saveName: async function(name) {
      apiMethods.crawlApi.setName(name);
    },
    getName: async function() {
      const ret = await this.Storage.get({ key: 'name' });
      return ret.value;
    },
    presentAlertPrompt: async function() {
      const self = this;
      const name = await self.getName();
      const alert = await alertController
      .create({
        cssClass: 'my-custom-class',
        header: 'Enter your name:',
        inputs: [
          {
            name: 'nameEntered',
            id: 'name-entered-id',
            value: name,
            placeholder: 'Your name',
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel')
            },
          },
          {
            text: 'Ok',
            handler: (alertData) => {
              console.log('Confirm Ok');
              self.saveName(alertData.nameEntered);
            },
          },
        ],
      });
      return alert.present();
    }
  }
});
</script>

<style scoped>

</style>