<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title>Tapcrawl</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-slides pager="true" :options="slideOpts">
    <ion-slide>
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Des Moines, IA</ion-card-subtitle>
          <ion-card-title>East Village</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <!--Keep close to Nature's heart... and break clear away, once in awhile,
          and climb a mountain or spend a week in the woods. Wash your spirit clean.-->
          <img src="/assets/images/eastvil.jpg" />
        </ion-card-content>
      </ion-card>
    </ion-slide>
    <ion-slide>
      <ion-card disabled="true">
        <ion-card-header>
          <ion-card-subtitle>Des Moines, IA</ion-card-subtitle>
          <ion-card-title>Court Avenue</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <!--Keep close to Nature's heart... and break clear away, once in awhile,
          and climb a mountain or spend a week in the woods. Wash your spirit clean.-->
          <img src="/assets/images/ctave.jpg" />
        </ion-card-content>
      </ion-card>
    </ion-slide>
    <ion-slide>
      <ion-card disabled="true">
        <ion-card-header>
          <ion-card-subtitle>West Des Moines, IA</ion-card-subtitle>
          <ion-card-title>West Glen</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <!--Keep close to Nature's heart... and break clear away, once in awhile,
          and climb a mountain or spend a week in the woods. Wash your spirit clean.-->
          <img src="/assets/images/westglen.jpg" />
        </ion-card-content>
      </ion-card>
    </ion-slide>
  </ion-slides>
  <ion-grid>
    <ion-row v-show="hasRecentlyCreatedCrawl">
      <ion-col>
        <ion-button expand="block" @click="() => router.push('/new-crawl-ready')"  color="success">Start Crawl</ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button expand="block" @click="() => router.push('/new')">New Crawl</ion-button>
      </ion-col>
    </ion-row>
    <ion-row v-show="!hasRecentlyCreatedCrawl && crawl && 'finished' != crawl.status">
      <ion-col>
        <ion-button expand="block" @click="() => router.push('/crawl-at-bar')">Crawl in Progress</ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button expand="block" @click="() => router.push('/join-crawl')">Join a crawl</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon
} from '@ionic/vue';
import { add, pin, walk, warning, wifi, wine } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
//import { WonderPush } from '@ionic-native/wonderpush/ngx';
import * as apiMethods from '../app/api.js';
import { Plugins } from '@capacitor/core';
//import { HTTP } from '@ionic-native/http/ngx';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
  },
  setup() {
    //const http = new HTTP;
    const { Storage, Device } = Plugins;
    apiMethods.crawlApi.Storage = Storage;
    apiMethods.crawlApi.Device = Device

    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: "auto",
      centeredSlides: true,
      freeMode: false,
      pagination: false
    };
    return {
      router: useRouter(),
      add,
      //http,
      console,
      slideOpts,
      warning,
      Storage
    }
  },
  data: function() {
    return {
      hasRecentlyCreatedCrawl: false,
      crawl: {}
    };
  },
  mounted: async function() {
    //apiMethods.crawlApi.wonderPush = new WonderPush;
    //const wonderPush = new apiMethods.WonderPush;
    if(!apiMethods.crawlApi.initialized){
      apiMethods.crawlApi.init();
      apiMethods.crawlApi.wonderPush.subscribeToNotifications();

      const uniqueId = await apiMethods.crawlApi.getUniqueId();
      console.log(uniqueId);
    }
    //if(apiMethods.crawlApi.code) {
      await apiMethods.crawlApi.getCurrentCrawl();
    //}
  },
  ionViewWillEnter: async function() {
    apiMethods.crawlApi.Storage = this.Storage;
    this.crawl = await apiMethods.crawlApi.getCurrentCrawl();
    this.hasRecentlyCreatedCrawl = await apiMethods.crawlApi.hasRecentlyCreatedCrawl();
  },
  methods: {
  },
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

.swiper-slide{
  width: calc(100% - 15vw);
}

.scroll{
    height: 100%;
}
</style>